import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, withCtx as _withCtx, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contentNode = _resolveComponent("contentNode")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dialog, _mergeProps({
      modelValue: _ctx.visible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event))
    }, _ctx.attrs, {
      "before-close": _ctx.beforeClose,
      onClose: _ctx.close
    }), {
      footer: _withCtx(() => [
        _renderSlot(_ctx.$slots, "footer", {}, () => [
          (_ctx.showCancelButton)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "default",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.cancelButtonText), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.showConfirmButton)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                type: "primary",
                disabled: _ctx.disabled,
                loading: _ctx.pending,
                onClick: _ctx.confirm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                ]),
                _: 1
              }, 8, ["disabled", "loading", "onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.text)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.text), 1)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.content)
          ? (_openBlock(), _createBlock(_component_contentNode, {
              key: 1,
              ref: "node",
              content: _ctx.content
            }, null, 8, ["content"]))
          : _renderSlot(_ctx.$slots, "default", { key: 2 })
      ]),
      _: 3
    }, 16, ["modelValue", "before-close", "onClose"]),
    _renderSlot(_ctx.$slots, "reference", {
      open: _ctx.open,
      close: _ctx.close
    })
  ], 64))
}