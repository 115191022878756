import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus, { ElMessage } from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import './styles/index.less'
import components from './components'
import './utils/webgl-utils'
import "tailwindcss/tailwind.css"

const app = createApp(App)
app
    .use(store)
    .use(router)
    .use(ElementPlus, { locale: zhCn, size: 'small' })
    .use(components)
    .mount('#app')

app.provide('$message', ElMessage)