import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicTableCell = _resolveComponent("BasicTableCell")!
  const _component_nest_column = _resolveComponent("nest-column", true)!
  const _component_el_table_column = _resolveComponent("el-table-column")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, ({ label, ...item }) => {
    return (_openBlock(), _createBlock(_component_el_table_column, _mergeProps({
      key: item._id,
      label: label
    }, item), {
      default: _withCtx((column) => [
        _createVNode(_component_BasicTableCell, _normalizeProps(_guardReactiveProps({ ...item, column })), null, 16),
        (item.columns && item.columns.length > 0)
          ? (_openBlock(), _createBlock(_component_nest_column, {
              key: 0,
              columns: item.columns
            }, null, 8, ["columns"]))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, 1040, ["label"]))
  }), 128))
}