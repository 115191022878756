import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderSlot as _renderSlot, withKeys as _withKeys, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_formItem = _resolveComponent("formItem")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, _mergeProps(_ctx.attrs, {
    ref: "form",
    size: _ctx.size,
    model: _ctx.model,
    onKeypress: _withKeys(_ctx.onEnter, ["enter"])
  }), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, ({ label, rules, type, required, prop, render, formatter, ...item }) => {
        return (_openBlock(), _createBlock(_component_el_form_item, {
          key: prop,
          class: "formClass",
          label: label,
          prop: prop,
          rules: _ctx.generalRules(rules || [], !!required, label, prop)
        }, {
          default: _withCtx(() => [
            (_ctx.editable)
              ? (_openBlock(), _createBlock(_component_formItem, {
                  key: 0,
                  render: render,
                  type: type,
                  model: _ctx.model,
                  prop: prop,
                  option: item
                }, null, 8, ["render", "type", "model", "prop", "option"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(formatter ? formatter(_ctx.model, prop, item) : _ctx.model[prop]), 1))
          ]),
          _: 2
        }, 1032, ["label", "prop", "rules"]))
      }), 128)),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["size", "model", "onKeypress"]))
}