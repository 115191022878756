
import { defineComponent } from 'vue'
import BasicTableCell from './Cell.vue'
export default defineComponent({
  name: 'NestColumn',

  components: {
    BasicTableCell,
  },

  inheritAttrs: false,

  props: {
    columns: {
      type: Array,
      required: true,
    },
    emptyText: String,
    emptyValue: Array,
  },
})
