
import Table from 'element-plus/lib/el-table/src/table.vue'

import { ComponentInternalInstance, computed, defineComponent, getCurrentInstance, nextTick, onMounted, PropType, provide, ref, toRefs, watch } from 'vue'
import NestColumn from './NestColumn.vue'
import BasicTableCell from './Cell.vue'
import { computedColumns } from './utils'
import { BasicTableColumns } from '../basic'

type IndexMethodCallback = (index: number, page: number, pageSize: number, table: ComponentInternalInstance | null) => string

export default defineComponent({
  name: 'BasicTable',

  components: {
    BasicTableCell,
    NestColumn,
  },

  inheritAttrs: false,

  props: {
    columns: {
      type: Array as PropType<BasicTableColumns[]>,
    },
    loading: Boolean,
    data: {
      type: Array as PropType<Record<string, any>[]>,
      default: () => [],
    },
    noDataText: {
      type: String,
      default: '没有更多数据了',
    },
    loadingText: {
      type: String,
      default: '数据加载中...',
    },
    showOverflowTooltip: {
      type: Boolean,
      default: true,
    },
    changeOnInit: {
      type: Boolean,
      default: true,
    },
    selectionKey: String,
    selectionRows: Array,
    columnOptions: Object as PropType<BasicTableColumns>,
    emptyValue: null,
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: Number,
    emptyText: {
      type: String,
      default: '-',
    },
    popoverOptions: {
      type: Object as PropType<Record<string, any> & { slot: string }>,
      default: () => ({}),
    },
    indexFn: {
      type: Function as PropType<IndexMethodCallback>,
    },
    showIndex: Boolean,
    indexLabel: {
      type: String,
      default: '序号',
    },
    pageOptions: Object,
  },

  emits: ['update:selection-rows', 'change', 'update:page', 'update:page-size'],

  setup(props, { attrs, emit }) {
    const table = getCurrentInstance()
    provide('table', table)
    const { loading, data, columns, selectionKey: key, selectionRows: rows, showOverflowTooltip, columnOptions, indexFn } = toRefs(props)
    const classList = computed(() => {
      return {
        'basic-table--loading': loading.value,
      }
    })
    const tableData = computed(() => (loading.value ? [] : data.value))
    const selectionChange = (value: any[]) => {
      const rows = key.value && typeof key.value === 'string' ? value.map((item) => item[key.value ?? '']) : value
      emit('update:selection-rows', rows)
    }
    const columnAttrs = computed(() => ({ showOverflowTooltip: showOverflowTooltip.value, align: 'center', ...columnOptions.value }))
    const computedEmptyValue = computed(() => {
      const values = [undefined, null, '']
      if (Array.isArray(props.emptyValue)) {
        return values.concat(props.emptyValue)
      }
      return values.concat([props.emptyValue])
    })

    const tableColumns = computed(() => computedColumns(columns.value, table))

    const pageAttrs = computed(() =>
      Object.assign(
        {
          pageSizes: [10, 15, 20, 25],
        },
        props.pageOptions
      )
    )

    watch(
      rows,
      (value) => {
        if (value) {
          const table = ref<typeof Table>()
          if (table.value) {
            for (const item of data.value) {
              const checked = key && typeof key === 'string' ? value.some((v) => item[key] === v) : value.includes(item)
              table.value.toggleRowSelection(item, checked)
            }
          }
        }
      },
      { immediate: true }
    )

    onMounted(() => {
      if (props.changeOnInit) {
        emit('change', props.page, props.pageSize)
      }
    })

    function indexMethod(callback?: IndexMethodCallback) {
      return (index: number) => {
        if (typeof callback === 'function') return callback(index, props.page, props.pageSize, table)
        if (indexFn.value) return indexFn.value(index, props.page, props.pageSize, table)
        return index + 1 + (props.page - 1) * props.pageSize
      }
    }

    function handleChange(type: 'update:page' | 'update:page-size', value: any) {
      emit(type, value)
      nextTick(() => {
        emit('change', props.page, props.pageSize)
      })
    }
    return {
      classList,
      attrs,
      tableData,
      selectionChange,
      columnAttrs,
      computedEmptyValue,
      tableColumns,
      indexMethod,
      pageAttrs,
      handleChange,
    }
  },
})
