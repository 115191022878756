import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'admin',
        redirect: '/login',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/components/Layout/View.vue'),
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "admin" */ '../views/admin/login/index.vue'),
            },
            {
                path: 'admin',
                name: 'layout',
                component: () => import('../views/admin/components/Layout/index.vue'),
                redirect: '/admin/dataManage',
                children: [
                    {
                        path: 'dataManage',
                        name: 'dataManage',
                        component: () => import('../views/admin/components/Layout/View.vue'),
                        meta: {
                            title: '数据管理',
                            isMenu: true
                        },
                        children: [
                            {
                                path: 'demandList', // 需求列表
                                name: 'demandList',
                                component: () => import('../views/admin/dataManage/demandList.vue'),
                                meta: {
                                    title: '需求列表',
                                    isMenu: true
                                }
                            },
                            {
                                path: 'decokdetail', // 对接详情
                                name: 'decokdetail',
                                component: () => import('../views/admin/dataManage/decokdetail.vue'),
                                meta: {
                                    title: '对接详情',
                                    isMenu: true
                                }
                            },
                            {
                                path: 'cluemanage', // 线索
                                name: 'cluemanage',
                                component: () => import('../views/admin/dataManage/cluemanage.vue'),
                                meta: {
                                    title: '线索管理',
                                    isMenu: true
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router
